const data = {
  'omni-journey.error-msg.incomplete-form': 'Form is incomplete.',
  'omni-journey.error-msg.encounter-issue':
    'Oops, we encountered a little issue...',
  'omni-journey.success-msg.upload-success': 'Upload successful!',
  'omni-journey.okay': 'Okay',
  'omni-journey.try-again-button': 'Try again?',
  'omni-journey.close-button': 'Close',
  'omni-journey.cancel-button': 'Cancel',
  'omni-journey.upload-button': 'Upload',
  'omni-journey.reset-button': 'Reset',
  'omni-journey.save-button': 'Save as Draft',
  'omni-journey.enable-button': 'Publish',
  'omni-journey.done-button': 'Done',

  'omni-journey.start-date-field.label': 'Start date',
  'omni-journey.start-time-field.label': 'Start time',
  'omni-journey.date-field.placeholder': 'Pick date',
  'omni-journey.time-field.placeholder': 'Select time',
  'omni-journey.category-field.label': 'Category',
  'omni-journey.category-field.placeholder': 'Select Category',
  'omni-journey.storeId-field.label': 'Store ID',
  'omni-journey.storeId-field.placeholder': 'Enter store ID',
  'omni-journey.storeType-field.label': 'Store Type',
  'omni-journey.storeType-field.placeholder': 'Enter store type',
  'omni-journey.fileUpload-field.placeholder': 'Click here to upload file',
  'omni-journey.upload.content': 'Upload Content',
  'omni-journey.upload.content.placeholder': 'Click here to upload content',
  'omni-journey.upload.top.background': 'Top Background',
  'omni-journey.upload.center.background': 'Center Background',
  'omni-journey.upload.bottom.background': 'Bottom Background',
  'omni-journey.upload.animation': 'Animation',
  'omni-journey.upload.center': 'Center Image',

  'omni-journey.voucher.header': 'Manage Vouchers',
  'omni-journey.voucher.upload-sequence': 'Upload Voucher Sequence',

  'omni-journey.scratch-card': 'Scratch Card',
  'omni-journey.create.scratch-card': 'Create Scratch Card',
  'omni-journey.before.scratch': 'Before scratch',
  'omni-journey.after.scratch': 'After scratch',
}

export default data
