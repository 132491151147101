import React from 'react'
import ScratchCardForm from './Form'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { tableProperties } from 'components/OmniJourney/utils'
import { getMessage } from 'lib/translator'
import './style.scss'

const linkButtons = () => {
  return (
    <div className="header-wrap">
      <Link to="/omni-journey/scratch-cards/add">
        <button className="primary button">
          <span className="text">
            {getMessage('omni-journey.create.scratch-card')}
          </span>
        </button>
      </Link>
    </div>
  )
}

const ManageScratchCards = (props) => {
  return (
    <ListingPageWithRoutes
      title="Manage Scratch Cards"
      addHeading="Create Scratch Card"
      editHeading="Edit Scratch Card"
      menu={props.menu}
      className="scratch-card-listing"
      api={{
        url: `/genie/lms/content`,
        transform: (res) => {
          return res.data.items
        },
        params: {
          contentType: 'scan',
          key: 'preload-asset',
          sk: props.match.params.sk,
        },
      }}
      headerActions={linkButtons}
      emptyState={{ actions: linkButtons }}
      form={{
        component: ScratchCardForm,
      }}
      tableProperties={tableProperties()}
      viewHeading="Scratch Card Details"
      modalClassName="resend-popup"
      dontSavePagination
      primaryKey="sk"
      tableDynamic
    />
  )
}

export default ManageScratchCards
