import React from 'react'
import { Row, Cell } from 'components/Table'
import { getMessage } from 'lib/translator'
import {
  ColumnDate,
  ColumnStatus,
  getStatus,
  TableAction,
} from 'components/AppHome/utils'
import { Link } from 'react-router-dom'
import Screen from './Screen'

const SCRATCH_CARD_COMPONENTS = [
  'imageTopBackground',
  'imageCenterBackground',
  'imageCenter',
  'imageBottomBackground',
  'animation',
]

const getComponents = (components, values) => {
  return components.map((component) => {
    return {
      key: component,
      url: values[component] || null,
      position: component.replace(/image|Background/g, '').toLowerCase(),
    }
  })
}

const tableProperties = () => {
  return {
    headers: ['', 'ID', 'Start Date', 'End Date', 'Status', 'Actions'],
    row: ({
      sk,
      pk,
      startTime,
      endTime,
      isDisabled,
      onAction,
      ...delegates
    }) => {
      const status = isDisabled
        ? getMessage('apphome.disabled')
        : getStatus(startTime, endTime)

      const components = SCRATCH_CARD_COMPONENTS.filter(
        (component) => component !== 'animation'
      )

      return (
        <Row>
          <Cell>
            <Screen components={getComponents(components, delegates)} />
          </Cell>
          <Cell className="column-campaign">
            <Link to={`/omni-journey/scratch-cards/edit/${sk}`}>{sk}</Link>
          </Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={sk}
              pk={pk}
              onAction={onAction}
              isEnable={!isDisabled}
              type="scratch_card"
            />
          </Cell>
        </Row>
      )
    },
  }
}

export { SCRATCH_CARD_COMPONENTS, tableProperties, getComponents }
